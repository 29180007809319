<template>
  <section class="h-auto bg-[#121212] challenges-bg" id="challenges">
      <div class="flex justify-center mx-4 pt-14 lg:pt-24">
        <div v-motion-pop-visible>
          <p class="bg-black text-white text-center mx-4 p-6 md:p-0 lg:p-10 uppercase text-2xl lg:text-4xl" style="box-shadow: inset 0 0 15px black; font-family: Zen Dots, system-ui;">Roobet Challenges</p>
        </div>
      </div>
      <div class="flex flex-wrap justify-center items-center mt-14 gap-2 md:gap-6">
          <a href="https://roobet.com/game/pragmatic:vs20fruitswx" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges1.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge1">
          </a>
          <a href="https://roobet.com/game/hacksaw:1233" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges2.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge2">
          </a>
          <a href="https://roobet.com/game/hacksaw:1309" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges3.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge3">
          </a>
          <a href="https://roobet.com/game/pragmatic:vs20sugarrushx" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges4.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge4">
          </a>
          <a href="https://roobet.com/game/nolimit:Outsourced" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges5.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge5">
          </a>
      </div>
      <br><br>
  </section>
</template>

<script>
export default {
  data() {
    return {
      challenges: [],
    };
  },
};
</script>
<style scoped>
.challenges-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
