<template>
  <!-- Leaderboard Section -->
  <section :class="loading == true ? 'h-screen': 'h-auto'" class="leaderboard-bg" id="leaderboard">
    <div class="flex justify-center px-8 md:px-8">
      <div class="flex flex-col justify-center items-center pt-14 mb-14 md:my-16 p-8 shadow-xl rounded-3xl">
        <div v-motion-pop-visible>
          <div class="bg-gradient-to-r from-[#CC6E00] via-[#FFE7CB] to-[#CC6E00] p-0.5">
            <p class="bg-black text-white text-center p-6 md:p-6 lg:p-10 uppercase text-2xl md:text-3xl lg:text-5xl [text-shadow:2px_2px_10px_var(--tw-shadow-color)] shadow-white" style="box-shadow: inset 0 0 15px black; font-family: Zen Dots, system-ui;">CHICKEN.GG LEADERBOARD</p>
          </div>
        </div>
        <div v-motion-pop-visible>
          <a href="https://chicken.gg/r/Ballyboy" target="_blank">
            <div class="text-[#FAFF00] text-center bg-gradient-to-r from-[#121212] via-[#573A00] to-[#121212] px-4 lg:px-20 py-4 rounded-full mt-4 text-sm transition ease-in-out hover:scale-110 duration-200" style="font-family: Russo One, sans-serif;">GET SIGNED UP ON <span class="text-[#FAFF00] text-xl">CHICKEN.GG</span> and be rewarded according to you rank!</div>
          </a>
        </div>
       <!--  <div v-if="loading == false" v-motion-pop-visible class="flex flex-col md:flex-row justify-center mt-4 items-center text-white text-center text-sm uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">
          THE CHICKEN.GG LEADERBOARD REWARDS WILL ONLY BE APPLICABLE WHEN THE TIMER RUNS OUT. REFER TO DISCORD FOR MORE INFO.
        </div>-->
        <div v-if="loading == false" v-motion-pop-visible class="flex flex-col md:flex-row justify-center mt-4 items-center text-white text-center text-sm uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">
          ***all wagers are counted during the inclusive dates: <span class="text-red-600 font-semibold">&nbsp; {{ startDate }} - {{ endDate }}</span>
        </div>
        <!-- <div v-motion-pop-visible class="flex justify-center text-center text-white mt-4 text-3xl uppercase font-semibold tracking-wider" style="font-family: Roboto Slab, serif;">Leaderboard under maintenance</div>
        <div v-motion-pop-visible class="flex flex-col lg:flex-row justify-center text-center items-center text-white text-sm mt-2 uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">
          We're working on it. Rest assured the correct winners will be displayed
        </div> -->
        <div v-motion-pop-visible class="flex justify-center text-white text-center font-light mt-4 py-3 px-5 rounded-2xl bg-black [text-shadow:2px_2px_5px_var(--tw-shadow-color)] shadow-white" style="font-family: Russo One, sans-serif; box-shadow: inset 0 0 15px black;">{{ !timer ? 'Calculating Timer...' : timer}}</div>
        <div class="flex flex-col justify-center">
          <div v-if="loading == false" @click="showTooltip1 = !showTooltip1" class="uppercase flex flex-row justify-center items-center gap-1 mt-4 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="h-6 w-6">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
            </svg>
            <span class="text-white text-sm" style="font-family: Russo One, sans-serif;">More Info</span>
          </div>
          <div v-if="showTooltip1">
            <div v-motion-pop-visible class="flex justify-center text-white text-center mt-4 text-lg uppercase font-semibold tracking-wider" style="font-family: Roboto Slab, serif;">Minimum 100 token wagered to claim prize each week</div>
            <div v-motion-pop-visible class="flex flex-col md:flex-row justify-center items-center text-white text-center text-sm uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">
            Leaderboard ends every Friday 11: 59:59PM UTC. <br> New leaderboard data will be available by Saturday 12:00 AM UTC
            </div>
          </div>
        </div>
        <div v-if="loading == true" class="text-center text-white text-2xl md:text-4xl mt-8 font-bold" style="font-family: Russo One, sans-serif;">Loading Leaderboard...</div>
        <div v-if="loading == false"  class="hidden md:block">
          <div class="flex flex-row justify-center items-center gap-6 lg:gap-24 mt-40">
            <div v-motion-pop-visible class="flex flex-col items-center mt-20 w-60">
              <img src="../assets/bronzetokentrophy.png" alt="bronze">
              <div class="text-3xl font-[400] text-white tracking-widest truncate" style="font-family: Russo One, sans-serif;">
                {{ top3.length > 0 && top3[2] != undefined ? top3[2].displayName : '' }}
              </div>
              <div class="text-sm text-white uppercase" style="font-family: Russo One, sans-serif;">
                wagered: <img src="../assets/chicken-prize-logo.png" class="inline-flex" alt="chicken-prize-logo"> {{ top3.length > 0 && top3[2] != undefined ? top3[2].wagerAmount.toFixed(2) : '' }}
              </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col items-center -mt-40 w-60">
              <img src="../assets/goldtokentrophy.png" alt="gold">
              <div class="text-3xl font-[400] text-white tracking-widest truncate" style="font-family: Russo One, sans-serif;">
                {{ top3.length > 0 && top3[0] != undefined ? top3[0].displayName : '' }}
              </div>
              <div class="text-sm text-white uppercase" style="font-family: Russo One, sans-serif;">
                wagered: <img src="../assets/chicken-prize-logo.png" class="inline-flex" alt="chicken-prize-logo"> {{ top3.length > 0 && top3[0] != undefined ? top3[0].wagerAmount.toFixed(2) : '' }}
              </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col items-center mt-20 w-60">
              <img src="../assets/silvertokentrophy.png" alt="silver">
              <div class="text-3xl font-[400] text-white tracking-widest truncate" style="font-family: Russo One, sans-serif;">
                {{ top3.length > 0 && top3[1] != undefined ? top3[1].displayName : '' }}
              </div>
              <div class="text-sm text-white uppercase" style="font-family: Russo One, sans-serif;">
                wagered: <img src="../assets/chicken-prize-logo.png" class="inline-flex" alt="chicken-prize-logo"> {{ top3.length > 0 && top3[1] != undefined ? top3[1].wagerAmount.toFixed(2) : '' }}
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8 mb:mb-24" style="font-family: Russo One, sans-serif;">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-xl font-thin text-left">
                    Ranks
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-xl font-thin text-center">
                    User
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-xl font-thin text-center">
                    Amount
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-xl font-thin text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in top4" :key="index">
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-left">
                    {{ getOrdinalSuffix(index + 4) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-center">
                    {{ item.displayName }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-center">
                    <div class="flex flex-row items-center">
                      <img src="../assets/chicken-prize-logo.png" alt="chicken-prize-logo">
                      <span class="text-white">{{ item.wagerAmount.toFixed(2) }}</span>
                    </div>
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] font-semibold text-right">
                    <div class="flex flex-row items-center">
                      <img src="../assets/chicken-prize-logo.png" alt="chicken-prize-logo">
                      <span class="text-green-400 ">{{ getRewardValue(index + 3) }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="loading == false"  class="block md:hidden">
          <div class="flex justify-center mt-8 mb:mb-24 text-xl" style="font-family: Russo One, sans-serif;">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-left">
                    Ranks
                  </th>
                  <th
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-center">
                    User
                  </th>
                  <th
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-center">
                    Amount
                  </th>
                  <th
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in top8" :key="index">
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left">
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[12px] text-white font-normal text-center">
                    {{ item.displayName }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[12px] text-white font-normal text-center">
                    <div class="flex flex-row items-center">
                      <img src="../assets/chicken-prize-logo.png" class="w-5" alt="chicken-prize-logo">
                      <span class="text-white">{{ item.wagerAmount.toFixed(2) }}</span>
                    </div>
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[12px] text-green-400 font-normal text-right">
                    <div class="flex flex-row items-center">
                      <img src="../assets/chicken-prize-logo.png" class="w-5" alt="chicken-prize-logo">
                      <span class="text-green-400 ">{{ getRewardValue(index) }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div v-if="loading == false" class="flex flex-col justify-center mt-8 lg:mt-14">
          <div @click="showTooltip2 = !showTooltip2" class="uppercase flex flex-col lg:flex-row justify-center items-center text-center gap-1 mt-4 cursor-pointer mx-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="h-6 w-6">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
            </svg>
            <span class="text-white text-sm" style="font-family: Russo One, sans-serif;">Roobet Rules</span>
          </div>
          <div v-if="showTooltip2">
            <div v-motion-pop-visible class="flex justify-center text-white mt-8 lg:mt-14 uppercase font-semibold text-sm lg:text-base tracking-wider px-2 lg:px-44 2xl:px-80" style="font-family: Roboto Slab, serif;">
              Your wagers on Roobet will count towards the leaderboard at the following weights based on the games you are playing. This helps prevent leaderboard abuse:

              <br><br>*Games with an RTP under 96% will contribute 100% of the amount wagered to the leaderboard.
              <br>*Games with an RTP of 97% will contribute 50% of the amount wagered to the leaderboard.
              <br>*Games with an RTP of 98- 99% and above will contribute 10% of the amount wagered to the leaderboard.
              <br>*Only house games and slots count towards leaderboard.
            </div>
            </div>
          </div>  -->
        </div>
      </div>
  </section>
  <section v-if="loading == false" class="h-auto">
    <div class="flex justify-center px-8 md:px-8">
      <div class="flex flex-col justify-center items-center mb-14 shadow-xl rounded-3xl">
        <div v-motion-pop-visible class="flex justify-center text-white text-center mx-4 mt-0 md:mt-14 text-lg md:text-3xl md:px-14 lg:px-0 uppercase font-black tracking-widest [text-shadow:2px_2px_5px_var(--tw-shadow-color)] shadow-white" style="font-family: Zen Dots, system-ui;">
          Previous Week Leaderboard Winners
        </div>
        <!-- Comment Here -->
        <!-- <div v-motion-pop-visible class="flex justify-center text-center text-white mt-4 text-xl md:text-3xl uppercase font-semibold tracking-wider" style="font-family: Roboto Slab, serif;">Waiting for Reset</div> -->

        <!-- Uncomment Start Here [uncomment next week]-->
        <div v-motion-pop-visible class="flex flex-col md:flex-row justify-center items-center text-white text-center text-sm uppercase font-thin tracking-wider mt-2" style="font-family: Roboto, serif;">
          ***all wagers are counted during the inclusive dates: <span class="text-red-600 font-semibold">&nbsp; {{ prevStartDate }} - {{ prevEndDate }}</span>
        </div>
        <div>
          <div class="flex justify-center mt-8 mb:mb-24 text-xl" style="font-family: Roboto, sans-serif;">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-left">
                    Ranks
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-center">
                    User
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-center">
                    Amount
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in previousLeaderboard" :key="index">
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] md:text-[16px] text-white font-semibold text-left">
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[13px] md:text-[16px] text-white font-semibold text-center">
                    {{ item.displayName }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[13px] md:text-[16px] text-white font-semibold text-center">
                     <div class="flex flex-row items-center">
                      <img src="../assets/chicken-prize-logo.png" alt="chicken-prize-logo">
                      <span class="text-white">{{ item.wagerAmount.toFixed(2) }}</span>
                    </div>
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[13px] md:text-[16px] text-green-400 font-semibold text-right">
                    <div class="flex flex-row items-center">
                      <img src="../assets/chicken-prize-logo.png" alt="chicken-prize-logo">
                      <span class="text-green-400 ">{{ getPrevRewardValue(index) }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Uncomment End Here -->
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';

export default {
  data() {
    return {
      top3: [],
      top4: [],
      top8: [],
      timer: '',

      showTooltip1: false,
      showTooltip2: false,

      previousLeaderboard: [],

      startDate: null,
      endDate: null,

      prevStartDate: null,
      prevEndDate: null,

      notFormattedEndDate: null,

      loading: false
    };
  },
  methods: {
    init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true
      axios.get(`${baseUrl}/api/leaderboard/chickengg`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(res => {
        
        axios.get(`${baseUrl}/api/leaderboard/chickengg?previous=true`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(rest => {
            const prevLeaderboard = rest.data.leaderboard;

            this.prevStartDate = moment(rest.data.startDate).format('MMMM DD, YYYY')
            this.prevEndDate = moment(rest.data.endDate).subtract(1, 'days').format('MMMM DD, YYYY')

            this.previousLeaderboard = prevLeaderboard.slice(0, 8).map((item) => {
              return item;
            });
        });

        const leaderboard = res.data.leaderboard;

        this.startDate = moment(res.data.startDate).format('MMMM DD, YYYY')
        this.endDate = moment(res.data.endDate).subtract(1, 'days').format('MMMM DD, YYYY')

        this.notFormattedEndDate = res.data.endDate

        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.top4 = leaderboard.slice(3, 8).map((item) => {
          return item;
        });

        this.top8 = leaderboard.slice(0, 8).map((item) => {
          return item;
        });

        this.startTimer();
        this.loading = false
      });
    },
    getOrdinalSuffix(n) {
      const s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRewardValue(rank) {
      const prizes = [500, 200, 100, 50, 25, 15, 10, 10];

      return prizes[rank];
    },
    getPrevRewardValue(rank){
      const prizes = [500, 200, 100, 50, 25, 15, 10, 10];

      return prizes[rank];
    },
    startTimer() {
      const getNextTargetDate = () => {
        const now = moment.utc();

        let targetDate = moment.utc(this.notFormattedEndDate).hour(23).minute(59).second(59);

        if (now.isAfter(targetDate)) {
          targetDate.add(1, 'week');
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let timerInterval;

      const updateTimer = () => {
        const nowUtc = moment.utc();
        const distance = targetDate.diff(nowUtc);

        if (distance < 0) {
          this.timer = 'Leaderboard ended';
          this.init();
          clearInterval(timerInterval);

          targetDate = getNextTargetDate();
          this.startTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.timer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.leaderboard-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
