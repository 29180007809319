<template>
  <!-- Main section start -->
  <section class="h-auto lg:h-auto main-bg" id="main">
          <div class="flex flex-col lg:flex-row justify-center items-center pt-24 md:pt-32 p-8 lg:p-0 gap-2 lg:gap-24 lg:pt-[20rem] 2xl:pt-60">
              <div class="flex flex-col px-0 lg:px-0">
                    <img v-motion-slide-visible-left src="../assets/join-roobet.png" class="w-[95%]" alt="sign-up">                         
                  <div class="flex flex-col justify-start mt-10 md:-ml-10 lg:-ml-12 lg:mt-40 2xl:mt-24">
                    <a href="https://roobet.com/?ref=kickballyboy" target="_blank">
                      <img v-motion-slide-visible-left src="../assets/kickballyboy.png" class="w-[700px] -mt-6 lg:-mt-14" alt="kickballyboy">
                    </a>
                  </div>
                  <div class="flex lg:hidden flex-col justify-start mt-10 md:-ml-10 lg:-ml-12 lg:mt-40">
                    <a href="https://chicken.gg/r/Ballyboy" target="_blank">
                      <img v-motion-slide-visible-left src="../assets/chicken.gg-code.png" class="w-[700px] -mt-6 lg:-mt-14" alt="kickballyboy">
                    </a>
                  </div>
              </div>
              <div>
                  <img v-motion-pop-visible src="../assets/ballyboy-logo.png" class="lg:-mt-20 2xl:mt-4" alt="roobet-logo">
              </div>
          </div>
          <div class="hidden lg:flex justify-end items-center px-5 pb-6 lg:pb-14 lg:pr-20 2xl:pr-52">
            <a href="https://chicken.gg/r/Ballyboy" target="_blank">
              <img v-motion-slide-visible-left src="../assets/chicken.gg-code.png" class="w-[700px] -mt-6 lg:-mt-14 2xl:mt-14" alt="chicken.gg code">
            </a>
          </div>
  </section>

  <section class="h-auto lg:h-[300px] shadow-inner bg-[#121212]" id="socials">
      <div class="flex flex-col justify-center">
          <div class="flex flex-col lg:flex-row justify-center items-center lg:gap-24 mt-14 md:mt-14 lg:mt-0">
             <div class="px-6">
                <img v-motion-slide-visible-bottom src="../assets/socials.png" alt="logo">
             </div>
             <div v-motion-pop-visible class="flex flex-wrap justify-center items-center mx-6 my-10 md:my-20 gap-14 lg:gap-18 px-6 py-6 md:px-20 md:py-14" style="box-shadow: inset 0 0 15px black">
                <a href="https://discord.com/invite/Rh6kTmSJuP" target="_blank">
                  <img v-motion-slide-visible-bottom src="../assets/discordlogo.png" alt="discord">
                </a>
                <a href="https://www.instagram.com/ballyboyoffical/" target="_blank">
                  <img v-motion-slide-visible-bottom src="../assets/instagram.png" alt="instagram">
                </a>
                <a href="https://x.com/Bally_BoyonKick" target="_blank">
                  <img v-motion-slide-visible-bottom src="../assets/twitter.png" alt="twitter">
                </a>
                <a href="https://www.youtube.com/@KickBally_Boy" target="_blank">
                  <img v-motion-slide-visible-bottom src="../assets/youtube.png" alt="youtube">
                </a>
                <a href="https://kick.com/bally_boy" target="_blank">
                  <img v-motion-slide-visible-bottom src="../assets/kick.png" alt="kick">
                </a>
             </div>
          </div>
      </div>
  </section>

  <ChallengesSection/>

  
</template>

<script>
import { defineComponent } from 'vue';
import ChallengesSection from '@/sections/ChallengesSection.vue';

export default defineComponent({
  components: {
      ChallengesSection
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
  },
});
</script>

<style scope>
body {
  background-color: #0B0B0C;
}
.main-bg {
  background-image: url('../assets/hero-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
