<template>
    <section class="h-auto">
        <RoobetLeaderboardSection/>
    </section>
</template>

<script>
import RoobetLeaderboardSection from '../sections/RoobetLeaderboardSection.vue';
    
    export default {
        components: {
            RoobetLeaderboardSection
        }
    }
</script>

<style scoped>

</style>