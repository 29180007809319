<template>
    <section class="h-screen store-bg">
        <div class="flex flex-col justify-center items-center px-5 pt-20 md:pt-24 lg:pt-52">
           <img src="../assets/under-construction.png" class="hidden md:block" alt="under-construction">
           <div v-motion-pop-visible class="flex md:hidden justify-center text-white text-center mt-14 md:mt-14 text-lg md:text-3xl md:px-14 lg:px-0 uppercase font-black tracking-widest [text-shadow:2px_2px_5px_var(--tw-shadow-color)] shadow-white" style="font-family: Zen Dots, system-ui;">
                under construction. coming soon...
            </div>
        </div>
    </section>
    <!-- <div class="px-8 pt-24 md:pt-24 lg:px-24 lg:pt-60 2xl:pt-80">
        <div class="flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-end gap-6">
            <div>
                <img v-motion-pop-visible src="../assets/store-heading.png" alt="store-heading">
            </div>
            <div class="flex flex-col justify-center items-center lg:justify-end lg:items-end" style="font-family: Zen Dots, system-ui;">
                <div v-motion-pop-visible class="point-box w-[240px] h-[100px]">
                    <div class="text-center mt-12 text-white text-sm tracking-widest">
                        POINTS: 999
                    </div>
                </div>
                <div class="mt-6 lg:mt-4">
                    <ul v-motion-pop-visible class="lg:list-disc space-y-2 text-center">
                        <li class="text-white text-sm uppercase tracking-widest">points are accumulated through leaderboard wager race.</li>
                        <li class="text-white text-sm uppercase tracking-widest">the higher your rank, the more points you get. easy peazy.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap justify-evenly items-center py-10 lg:py-24 gap-8 lg:gap-14">
            <div v-for="n in 10" :key="n" class="transition ease-in-out hover:scale-110 duration-300 bg-gradient-to-b from-[#3C2B0D] via-[#E0B481] to-[#3C2B0D] p-2 cursor-pointer shadow-2xl" style="font-family: Russo One, sans-serif;">
                <div class="flex flex-col justify-start items-center bg-gradient-to-b from-[#010203] via-[#010203] to-[#020203] w-[240px] h-[290px] gap-4">
                    <p class="text-white uppercase px-8 pt-4" style="font-family: Zen Dots, system-ui;">Bonus Buy</p>
                    <ul class="list-disc ml-2 space-y-2 px-4">
                        <li class="text-white uppercase text-[9px]">
                            gates of olympus 1000 $20 bonus buy
                        </li>
                        <li class="text-white uppercase text-[9px]">
                            You keep all profit
                        </li>
                    </ul>
                    <div class="mt-auto pb-3">
                        <div class="flex flex-col justify-center items-center gap-3">
                            <img src="../assets/card-divider.png" alt="card-divider">
                            <p class="text-white uppercase text-sm" style="font-family: Zen Dots, system-ui;">20 Points</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
export default {
    
}
</script>

<style scoped>
.point-box {
  background-image: url('../assets/point-box.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
</style>