import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import ErrorView from '../views/ErrorView.vue'
import MainPage from '../pages/MainPage.vue'
import StorePage from '../pages/StorePage.vue'
import RoobetLeaderboardPage from '../pages/RoobetLeaderboardPage.vue'
import ChickenGGLeadeboardPage from '../pages/ChickenGGLeadeboardPage.vue'

const routes = [
  {
    path: '/',
    component: MainView,
    meta: { alreadyAuth: true },
    children: [
      {
        path: '/home',
        alias: '/',
        component: MainPage
      },
      {
        path: '/roobet-leaderboard',
        component: RoobetLeaderboardPage
      },
      {
        path: '/chicken.gg-leaderboard',
        component: ChickenGGLeadeboardPage
      },
      {
        path: '/store',
        alias: '/store',
        component: StorePage
      },
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorView',
    component: ErrorView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { top: 0}
  },
})


export default router
