<template>
    <!-- Navbar start -->
    <nav :class="{'bg-black shadow-md transition-opacity bg-opacity-80': isScrolled || showMenu, 'bg-transparent': !isScrolled && !showMenu }" class="w-full fixed p-2 px-5 md:px-5 lg:px-8 flex justify-between z-40 transition-all">
        <a @click="showMenu = false" href="/#main">
            <div class="h-full flex flex-row justify-between items-center">
                <img v-motion-pop-visible src="../assets/ballyboy_x_roobet.png" class="w-[80%] md:w-full" alt="logo">
            </div>
        </a>

        <button role="menubar" class="lg:hidden" @click="toggleMenu()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="w-9 h-9">
                <path fill-rule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
            </svg>
        </button>

        <div :class="{
            'bg-opacity-80 bg-black lg:bg-transparent': isScrolled || showMenu,
            'bg-opacity-80': !isScrolled && !showMenu,
            'hidden': !showMenu && isMobile(),
            'flex': showMenu || !isMobile(),
            }"
            class="absolute left-0 right-0 mt-10 md:mt-10 shadow lg:flex flex-col gap-4 justify-end items-start lg:item-center lg:mt-0 p-2 lg:flex-row lg:static lg:shadow-none lg:translate-y-0 transition-all"
            role="menu" :aria-expanded="showMenu.toString()"
        >
            <div v-motion-pop-visible class="flex flex-col gap-[40px] lg:mb-0 lg:flex-row justify-evenly uppercase whitespace-nowrap text-lg text-white" style="font-family: Russo One, sans-serif;">
            <a href="/#socials" @click="showMenu = false; showDropdown = false" role="menuitem" class="px-4 py-1.5 routerBtn text-base font-bold transition-colors">
                Socials
            </a>
            <a href="/#challenges" @click="showMenu = false; showDropdown = false" role="menuitem" class="px-4 py-1.5 routerBtn text-base font-bold transition-colors">
                Challenges
            </a>
            <a @click="toggleDropdown" role="menuitem" class="dropdown inline-block relative px-4 py-1.5 routerBtn text-base font-bold transition-colors cursor-pointer">
                <div class="text-white flex flex-row items-center gap-2">
                    Leaderboard
                    <svg class="h-5 w-5" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
                <ul v-motion-pop-visible :class="{'hidden': !showDropdown}" class="dropdown-menu absolute pt-2 rounded-md z-10">
                    <li><a href="/roobet-leaderboard" @click="showMenu = false; showDropdown = false">
                        <div :class="showMenu || isScrolled ? 'lg:bg-opacity-80  bg-black': ''" class="text-white hover:text-[#FFB800] py-2 px-4 block whitespace-no-wrap -ml-4">
                            <span class="text-[#FFB800]">•</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="white" class="h-4 w-4 inline-flex">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                            Roobet Leaderboard
                        </div>
                        </a>
                    </li>
                    <li><a href="/chicken.gg-leaderboard" @click="showMenu = false; showDropdown = false">
                        <div :class="showMenu || isScrolled ? 'lg:bg-opacity-80 bg-black': ''" class="text-white hover:text-[#FFB800] py-2 px-4 block whitespace-no-wrap -ml-4">
                            <span class="text-[#FFB800]">•</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="white" class="h-4 w-4 inline-flex">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                            Chicken.gg Leaderboard
                        </div>
                        </a>
                    </li>
                </ul>
            </a>
            <a href="/store" @click="showMenu = false; showDropdown = false" role="menuitem" class="px-4 py-1.5 routerBtn text-base font-bold transition-colors">
                Store
            </a>
            </div>
        </div>
    </nav>
    <!-- Navbar end -->
</template>

<script>
export default {
    data() {
        return {
            isScrolled: false,
            showMenu: false,
            showDropdown: false,
        };
    },
    methods: {
        handleScroll() {
            this.isScrolled = window.scrollY > 0;

            if (this.showMenu && window.innerWidth < 768) {
                this.isScrolled = true;
            }
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
            if (this.showMenu && window.innerWidth < 768) {
                this.isScrolled = true;
            } else if (!this.showMenu && window.scrollY === 0) {
                this.isScrolled = false;
            }
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        isMobile() {
            return window.innerWidth < 1024; // Adjust based on your 'lg' breakpoint in Tailwind CSS config
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>

</style>
