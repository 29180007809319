<template>
    <section class="h-auto store-bg">
        <StoreSection/>
    </section>
</template>

<script>
import StoreSection from '../sections/StoreSection.vue';
export default {
    components: {
        StoreSection
    }
}
</script>

<style  scoped>
.store-bg {
  background-image: url('../assets/store-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
</style>